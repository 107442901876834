@import '../../styles/vars.scss';

.footer {
    border-top: 1px solid #ececec;
}

.content {
    width: 100%;
    max-width: $container-w;
    margin-left: auto;
    margin-right: auto;
    padding: $gap-m;
    display: flex;
    justify-content: space-between;
    @media (max-width: 360px){
        flex-direction: column;
    }
}


.menu{
    display: grid;
    grid-gap: 10px 20px;
    grid-auto-flow: column;
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        grid-auto-columns: auto;
        grid-auto-flow: column;
        grid-gap: 5px 20px;
        align-items: start;
        @media (max-width: 600px){
            grid-auto-flow: row;
        }
        a{
            color: #222;
            &:hover{
                color: $primary;
            }
        }
    }
}
.contact{
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    grid-gap: 5px 20px;
    align-items: start;
    @media (max-width: 600px) and (min-width: 361px){
        grid-auto-flow: row;
    }
    @media (max-width: 360px) {
        padding-top: 10px;
        margin-top: 10px;
        border-top: 1px solid #ececec;
    }
    a{
        color: #222;
        &:hover{
            color: $primary;
        }
    }
}