@import '../../styles/vars.scss';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination {
    margin-top: $gap-l;
}

.table {
    max-width: 100%;
    overflow-x: scroll;
}

.sort {
    display: flex;
}

.sortItem {
    margin-right: $gap-m;
    margin-bottom: $gap-m;
}