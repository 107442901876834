@import '../../styles/vars.scss';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination {
    margin-top: $gap-l;
}

.table {
    max-width: 100%;
    overflow-x: scroll;
}

.status {
    font-weight: 700;
}

.link {
    padding: 0 !important;
    height: 0 !important;
}

.paymentLine {
    position: absolute;
    left: 0;
    top: 0;
    width: 3px;
    height: 100%;
    display: block;
}
