@import '../../styles/vars.scss';

.formItem {
    margin-bottom: $gap-s;
}

.label {
    margin-bottom: 0 !important;
}

.files {
    display: flex;
}

.return {
    margin-right: $gap-m;
}

.buttons {
    margin-top: $gap-xl;
}

.badImages {
    margin-top: 0 !important;
}

.radio__name {
    img {
        margin-left: 10px;
    }
}

.creatingOrder {
    padding: 24px !important;
    background: #fff;
    box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
    border-radius: 6px;

    &_buttons {
        position: sticky;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 24px 0 10px;
        background: #fff;
        border-top: 1px solid #eeeeee;
        z-index: 2;
    }
}

.typeOrder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

    img {
        max-width: 50px;
    }

    &_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        gap: 20px;
        background: #fff;
        box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
        border-radius: 6px;
        cursor: pointer;
        opacity: 0.7;
        transition: all 0.6s;
        width: 135px;
        height: 135px;

        @media (min-width: 480px) {
            width: 300px;
            height: 300px;
        }


        &:hover:not(:disabled) {
            background: #f2deea;
            opacity: 1;
        }

        &:where(:disabled) {
            cursor: default;
            opacity: 0.2;
        }
    }

    &__title {
        font-weight: 300;
        font-size: 0.7em;

        @media (min-width: 480px) {
            font-size: 1.6em;
        }
    }

    &_active {
        background: #f2deea;
        opacity: 1;
    }

    &_all_active &_item {
        width: 120px;
        height: 120px;
        transition: all 0.6s;

        img {
            width: 40px;
            transition: all 0.6s;
        }
    }

    &_all_active &__title {
        font-size: 0.8em;
        transition: all 0.6s;
    }
}