.footer {
  background: #000000;
  padding: 2.4rem 0;

  @include media($md) {
    padding: 6.4rem 0 4.0rem;
  }
  .logoFooter{
    cursor: pointer;
  }
  
  &-title {
    @include fontStyle($base, 1.5rem, 2.0rem, 600);
    color: #FFFFFF;
    margin-bottom: 0.8rem;
  }

  &-menu {
    &__item {
      margin-bottom: 0.8rem;
      list-style: none;

      &:last-child {
        margin-bottom: 0;
      }

      .btn,
      a {
        text-align: left;
        @include fontStyle($base, 1.5rem, 2.0rem, 400);
        color: #FFFFFF;
      }
    }
  }

  &-links {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.3rem 2.4rem;

    // @include media($lg) {
    //   @include flexWidth(50%);
    // }

    &:last-child {
      margin-bottom: 0;
    }

    .footer-title {
      @include flexWidth(100%);
      padding: 0 0.3rem;
    }

    &__item {
      padding: 0 0.3rem;
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;

    &__item {
      margin-bottom: 24px;
      @include flexWidth(100%);

      .small{
        a{
          font-size: 1.1rem;
        }
      }

      &--logo {
        @include flexWidth(100%);

        @include media($md) {
          @include flexWidth(25%);
        }

        @include media($lg) {
          @include flexWidth(20%);
        }
        
        img  {
          margin-bottom: 1.6rem;
        }
      }
      
      &--menu {
        @include flexWidth(50%);

        @include media($md) {
          padding-top: 2.2rem;
          @include flexWidth(25%);
        }

        @include media($lg) {
          @include flexWidth(20%);
        }

        .footer-title {
          @include media($md) {
            margin-bottom: 2.4rem;
          }
        }
      }
      
      &--links {
        @include media($md) {
          padding-top: 2.2rem;
          @include flexWidth(25%);
        }

        @include media($lg) {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          @include flexWidth(20%);
        }
      }
      
      &--copyright {
        margin-bottom: 0;

        .copyright {
          @include fontStyle($base, 1.3rem, 1.8rem, 400);
          text-align: center;
          color: #FFFFFF;
          max-width: 20.0rem;
          margin: 0 auto;
        }
      }
    }
  }
}
