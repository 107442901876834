@import '../../styles/vars.scss';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table {
    max-width: 100%;
    overflow-x: scroll;
}

.actions {
    display: flex;
    justify-content: flex-end;
}

.status {
    font-weight: 700;
    margin-right: $gap-m;
}

.pagination {
    margin-top: $gap-l;
}

.small_img {
    cursor: pointer;
    padding: 0 10px 10px 0;
    display: inline-block;
    width: 130px !important;
    height: 130px !important;
    object-fit: cover;
    object-position: center;
}

.previewGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.formItemTitle {
    margin-top: 10px;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
}