@import '../../styles/vars.scss';


.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: #fff;
    box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
    border-radius: 6px;
    padding: 24px;
    margin-top: 20px;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.block {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 20px;

    @media (min-width: 768px) {
        flex: 1 0;
    }
}

.blockForm {
    display: flex;
    min-width: 240px;

    @media (min-width: 992px) {
        justify-content: center;
    }

}