// ОТСТУПЫ
$gap-2xs: 4px;
$gap-xs: 8px;
$gap-s: 12px;
$gap-m: 16px;
$gap-l: 20px;
$gap-xl: 24px;
$gap-2xl: 32px;
$gap-3xl: 40px;
$gap-4xl: 48px;
$gap-5xl: 72px;

$gap-2xs-neg: -4px;
$gap-xs-neg: -8px;
$gap-s-neg: -12px;
$gap-m-neg: -16px;
$gap-l-neg: -20px;
$gap-xl-neg: -24px;
$gap-2xl-neg: -32px;
$gap-3xl-neg: -40px;
$gap-4xl-neg: -48px;
$gap-5xl-neg: -72px;

// BOX-SHADOW
$light-shadow: 0 0 10px rgba(0,0,0,0.1);
$header-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

// COLOR
$error-color: #e64c4c;
$main-color: #2e2e2e;
$bg-color: #f5f3f7;
$header-bg: #f8f9fa;
$gray1: #E8EAEB;
$gray2: #A5A3A3;

$primary:#171717;

// FONT

// WIDTH
$container-w: 1160px;