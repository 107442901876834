@import '../../styles/vars.scss';

.about{
    margin-top: 30px;
    b{
        font-size: 1.6em;
    }
}

.title{
    font-size: 1.4em;
    font-weight: bold;
    margin-top: 30px;
}

.table{
    margin-top: 15px;
}

.desc{
    margin-top: 15px;
}

.list{
    margin-top: 15px;
    margin-left: 40px;
    &_decimal{
        list-style: decimal;
    }
}