@import '../../styles/vars.scss';

.filter {
    box-shadow: $light-shadow;
    padding: $gap-l $gap-m $gap-xl;
    margin: $gap-xl 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filterItems {
    display: flex;
    align-items: center;
}

.filterItem {
    margin-right: $gap-m;
}

.select {
    min-width: 160px;
}

.filterDate {
    min-width: 260px;
    margin-right: $gap-m;
}