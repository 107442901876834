@import '../../styles/vars.scss';


.promocode {
    display: flex;
    max-width: 500px;
    margin-bottom: $gap-m;
}

.paidButton {
    display: flex !important;
    align-items: center;
    gap: 6px;
    margin-bottom: $gap-m;

    svg {
        width: 18px;
    }
}

.blockPromocode {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #fff;
    box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
    border-radius: 6px;
    padding: 24px;
    margin-top: 20px;
    @media (max-width: 480px){
        align-items: flex-start;
        flex-direction: column;
    }    
}


.cart {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    background: #fff;
    box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
    border-radius: 6px;
    padding: 24px;
    margin-top: 20px;

    @media (min-width: 768px) {
        gap: 20px;
        align-items: flex-end;
        flex-direction: row;
    }


    &_paycode {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &_line {
        flex: 1 0;
    }
}