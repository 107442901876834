$primary: #e53e29;
$secondary: #171717;

$color_767474: #767474;
$color_C4C4C4: #c4c4c4;
$color_E53F29: rgba(#e53f29, 0.2);
$color_00_02: rgba(#000000, 0.2);
$color_17_70: rgba(#171717, 0.7);
$color_403F3F_05: rgba(#403f3f, 0.5);

$white: #ffffff;

$base: 'Open Sans', sans-serif;
$title: 'Open Sans Condensed', sans-serif;
$title2: 'Constantia';

//min 320
$xs: 20em; 
//min 480
$sm: 30em;
//min 768
$md: 48em;
//min 960
$lg: 60em;
//min 1070
$lg2: 64em;
//min 1280
$xl: 80em;
//min 1415
$xl2: 88.438em;
//min 1600
$xxl: 100em;


// ОТСТУПЫ
$gap-2xs: 4px;
$gap-xs: 8px;
$gap-s: 12px;
$gap-m: 16px;
$gap-l: 20px;
$gap-xl: 24px;
$gap-2xl: 32px;
$gap-3xl: 40px;
$gap-4xl: 48px;
$gap-5xl: 72px;

$gap-2xs-neg: -4px;
$gap-xs-neg: -8px;
$gap-s-neg: -12px;
$gap-m-neg: -16px;
$gap-l-neg: -20px;
$gap-xl-neg: -24px;
$gap-2xl-neg: -32px;
$gap-3xl-neg: -40px;
$gap-4xl-neg: -48px;
$gap-5xl-neg: -72px;

// BOX-SHADOW
$light-shadow: 0 0 10px rgba(0,0,0,0.1);
$header-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);