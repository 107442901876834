@import '../../styles/vars.scss';

.imagesBlock {
    padding-right: $gap-xl;
    margin-bottom: $gap-xl;
}

.imagesList {
    display: flex;
}

.wrapperImage {
    flex: 0 0 33.333%;
    border: 2px solid #fff;
}

.image {
    height: 150px !important;
    object-fit: cover;
}

.statuses {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.updateStatusLoading {
    font-size: 16px;
    margin-left: $gap-m;
}

.imagesTitle {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
}

.imageError {
    font-size: 12px;
    padding-bottom: $gap-2xs;
}

.cart {
    position: sticky;
    z-index: 1;
    bottom: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    background: #fff;
    box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
    border-radius: 6px;
    padding: 24px;
    margin-top: 20px;
}