.image {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #E8EAEB;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.upload {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 145px;
    height: 145px;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    transition: border-color 0.3s;
    font-weight: bold;

    @media (max-width: 480px) {
        width: 130px;
        height: 130px;
    }

    &:hover {
        border-color: #222;
    }
}

.upload__loading {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 145px;
    height: 145px;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    transition: border-color 0.3s;
    font-weight: bold;

    @media (max-width: 480px) {
        width: 130px;
        height: 130px;
    }

    &:hover {
        border-color: #eb3e3e;
    }
}

.imagesList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    img {
        object-fit: cover;
        width: 145px;
        height: 145px;

        @media (max-width: 480px) {
            width: 130px;
            height: 130px;
        }

    }

    &_item {
        position: relative;
        display: flex;
        flex-direction: column;

        &:hover .delete {
            display: block;
        }
    }
}

.delete {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: none;
    padding: 4px 8px;
    color: #fff;
    background: rgb(171, 18, 18);
    cursor: pointer;
    border-radius: 3px;
    text-align: center;
}