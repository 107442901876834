@import '../../styles/vars.scss';

.group {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: row;
}

.item {
    margin-bottom: $gap-m;
}

.container {
    width: 100%;
    height: 400px;
    max-width: 400px;
}

.colorItem {
    width: 25px;
    height: 25px;
}

.gradient1 {
    background: linear-gradient(0deg, #474bff 0%, #bc48ff 100%);
}

.gradient2 {
    background-image: linear-gradient(to bottom right, #FDABDD, #374A5A);
}

.gradient3 {
    background-image: linear-gradient(to bottom right, #5761B2, #1FC5A8);
}

.gradient4 {
    background: linear-gradient(90deg, #f598a8, #f6edb2);
}

.gradient5 {
    background: linear-gradient(90deg, #cfecd0, #a0cea7, #9ec0db);
}

.statistic {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fff;
    color: #222;
    border-radius: 5px;
    box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;

    &:not(&__horizon) {
        max-width: 360px;
    }

    &_mb {
        margin-bottom: 20px;
    }

    &_title {
        padding: 20px;
    }

    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.4;
        color: inherit;
    }

    &_body {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 20px;
    }

    &:where(&__horizon) &_body {
        flex-direction: row;
    }

    &__content {
        width: 100%;
        height: 300px;
    }

    &:where(&__horizon) &__content {
        width: 100%;
        max-width: 60%;
        height: 400px;
    }

    &__text {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    &_values {
        width: 100%;
        display: flex;
        gap: 10px;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        background: #c9c9c936;
        color: #222;
        padding: 10px;
        border-radius: 4px;

        &__content {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

    }

    .ant-statistic-title {
        color: #fff !important;
    }
}