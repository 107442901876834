* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
}

/* 
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} */

/* h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
input {
    color: #2e2e2e;
} */
.ant-upload-list-picture-card-container,
.ant-upload.ant-upload-select-picture-card {
    width: 130px;
    height: 130px;
}

.ant-form-item-control-input .react-tel-input .form-control {
    width: 100%;
    border-radius: 2px;
}

.ant-table-thead>tr>th {
    color: #000 !important;
    text-transform: uppercase;
    font-weight: bold;
    background-color: #fff;
    border-top: 1px solid #f0f0f0;
    &::before{
        background: #959595;
    }
}

.ant-scroll-number-only-unit {
    color: inherit;
}

.ant-select-item-option-content {
    white-space: initial;
}

select {
    width: 100%;
}

.ant-pagination-options {
    display: none;
}

.ant-btn{
    border-radius: 4px;
}

.payment_1 {
    background: #49a57b;
}

.payment_0 {
    background: #d05252;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    white-space: pre-line;
}