.rezume {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    @media (max-width: 767px) {
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }
    &_email {
        margin-top: 35px;
        margin-bottom: 40px;
        display: block;
        font-weight: bold;
    }
}

.block {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    &_name {
        position: relative;
        font-size: 2.2rem;
        font-weight: bold;
        margin-bottom: 10px;
        display: block;
        &:before {
            content: "";
            display: block;
            width: 3px;
            height: 100%;
            background: #c594b1;
            position: absolute;
            left: -15px;
            top: 0;
        }
    }
    &_time {
        margin-bottom: 5px;
        font-size: 0.9em;
    }
    &_graf {
        margin-bottom: 5px;
        font-size: 0.9em;
    }
    &_treb {
        margin-bottom: 5px;
        font-size: 0.9em;
    }
}
