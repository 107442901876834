.float{
    position: fixed;
    left: 10px;
    bottom: 8dvh;
    z-index: 10;
    @media (min-width: 1920px){
        left: 21dvw;
    }
    
}

.btn{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 40px;
    height: auto;
    box-shadow: none;
    min-height: 40px;
    border-radius: 50%;
    background-color: #1677ff;
    color: #fff;
    font-size: 25px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    margin-top: 20px;
}

.wrapper{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    display: none;
}

.open{
    display: flex !important;
}

.item{
    cursor: pointer;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffffff;
    transition: background-color 0.2s;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    font-size: 20px;
    &:hover{
        background-color: rgba(0, 0, 0, 0.06);
    }
}