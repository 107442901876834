// *,
// *::before,
// *::after {
//   box-sizing: inherit;
// }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-family: $base;

  &.overflow {
    overflow: hidden;
  }
}

h1 {
  @include fontStyle($title2, 4.0rem, 4.9rem, 500);
  color: #000000;

  @include media($md) {
    @include fontSize(4.8rem, 5.9rem);
  }

  @include media($lg) {
    @include fontSize(7.2rem, 8.8rem);
  }
}

h2 {
  @include fontStyle($title, 2.0rem, 2.7rem, bold);
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 1.6rem;

  @include media($md) {
    @include fontSize(3.6rem, 4.9rem);
    margin-bottom: 2.4rem;
  }
}

.h2--regular {
  @include fontStyle($base, 1.5rem, 2.0rem, 400);
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 2.4rem;

  @include media($md) {
    @include fontSize(3.0rem, 4.1rem);
    margin-bottom: 3.2rem;
  }
}

h3 {
  @include fontStyle($title, 1.5rem, 2.0rem, bold);
  color: #000000;
  margin-bottom: 1.6rem;

  @include media($md) {
    @include fontSize(2.0rem, 2.7rem);
  }
}

p {
  @include fontStyle($base, 1.5rem, 2.0rem, 400);

  @include media($md) {
    @include fontSize(1.8rem, 2.8rem);
  }

  a {
    text-decoration: underline;
  }
}

.text-center {
  text-align: center;
}

.radio-bg {
  display: inline-block;
  padding: 17px;
  background: #FFFFFF;
  border: 1px solid #E8EAEB;
  border-radius: 4px;

  span.ant-radio + * {
    font-size: 16px;
  }
}

picture {
  display: block;
  font-size: 0;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: 0;
  background-color: transparent;
}

.btn {
  padding: 10px 29px;
  background: #000000;
  border: 1px solid #000000;
  border-radius: 4px;
  @include fontStyle($base, 1.5rem, 2.0rem, 400);
  text-align: center;
  color: #FFFFFF;
  width: 100%;
  max-width: 100%;
  cursor: pointer;

  @include media($md) {
    width: auto;
  }

  @media (max-width: 480px) {
    padding: 10px 20px;
  }

  &-small {
    padding: 7px 15px;
  }


  &--transparent {
    background: transparent;
    color: #000000;
  }

  &--null {
    background-color: transparent;
    border: none;
    padding: 0;
    color: #000000;
  }
}

.hidden {
  display: none !important;
}

.container {
  width: 100%;
  // max-width: 1415px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 0.8rem;
  display: block;

  @include media($lg) {
    padding: 0 1.2rem;
  }

  @include media($xl2) {
    max-width: 120.0rem;
  }

  &--big {
    max-width: 1440px;
  }
}

.row {
  margin-left: -0.8rem;
  margin-right: -0.8rem;

  @include media($lg) {
    margin-left: -1.2rem;
    margin-right: -1.2rem;
  }
}

.logo {
  &__link {
    display: inline-block;
  }
}

img {
  &.fit {
    object-fit: cover;
    max-width: none;
  }
}

.banner {
  margin-bottom: 6.4rem;
  position: relative;
  overflow: hidden;

  @include media($md) {
    margin-top: 4.4rem;
    display: flex;
  }

  @include media($lg) {
    margin-top: 0;
    margin-bottom: 5.0rem;
  }

  @include media($xl) {
    margin-bottom: 6.4rem;
  }

  @include media($xl2) {
    max-width: 141.6rem;
    margin-right: auto;
    margin-left: auto;
  }

  &__image {
    @include media($md) {
      @include flexWidth(100%);
      text-align: right;
    }
  }

  .align-items-center {
    display: flex !important;
    align-items: center;
  }

  &__content {
    margin-top: -1.5rem;

    @include media($md) {
      @include flexWidth(100%);
      position: relative;
      left: -100%;
      margin-top: 0;
    }

    .container--big {
      display: flex;
    }

    &-inner {
      @include media($md) {
        @include flexWidth(28.5rem);
        margin-left: 8.0rem;
        padding-top: 4.2rem;
      }

      @include media($lg) {
        @include flexWidth(48.5rem);
      }

      @include media($xl2) {
        margin-left: 0;
      }
    }

    h1 {
      margin-bottom: 1.6rem;

      @include media($lg) {
        margin-bottom: 4.5rem;
      }
    }

    h2 {
      margin-bottom: 1rem;

      @include media($lg) {
        margin-bottom: 1.5rem;
      }
    }

    .btn {
      cursor: pointer;
      margin-top: 3.2rem;

      @include media($lg) {
        margin-top: 5.6rem;
      }
    }
  }

  &__absolute {
    display: none;

    @include media($md) {
      position: absolute;
      top: 3.6rem;
      left: 0.8rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    @include media($xl) {
      left: 2.4rem;
    }

    @include media($xl2) {
      left: 4.2rem;
    }
  }

  &__social {
    display: flex;
    flex-direction: column;

    &-item {
      margin-bottom: 0.8rem;
    }
  }

  &__about-service {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:before {
      content: "";
      display: block;
      width: 0.1rem;
      height: 5.0rem;
      background-color: #000000;
    }

    span {
      writing-mode: tb-rl;
      transform: scale(-1);
      @include fontStyle($base, 1.5rem, 1.8rem, 300);
      color: #000000;
      margin-top: 1.6rem;
      margin-bottom: 1.6rem;
    }

    &:after {
      content: "";
      display: block;
      width: 0.8rem;
      height: 3.5rem;
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='35' viewBox='0 0 8 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.56637 34.3536C3.76163 34.5488 4.07821 34.5488 4.27348 34.3536L7.45546 31.1716C7.65072 30.9763 7.65072 30.6597 7.45546 30.4645C7.26019 30.2692 6.94361 30.2692 6.74835 30.4645L3.91992 33.2929L1.09149 30.4645C0.896233 30.2692 0.57965 30.2692 0.384388 30.4645C0.189126 30.6597 0.189126 30.9763 0.384388 31.1716L3.56637 34.3536ZM3.41992 0L3.41992 34H4.41992L4.41992 0L3.41992 0Z' fill='black'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.content {
  display: flex;
  flex-wrap: wrap;

  &--360 {
    @include media($lg) {
      flex-wrap: nowrap;
    }
  }

  &--360 & {
    &__item {
      @include flexWidth(100%);

      // @include media($sm) {
      //   @include flexWidth(36.0rem);
      // }

      @include media($md) {
        @include flexWidth(36.0rem);
      }

      @include media($lg2) {
        @include flexWidth(36.0rem);
      }

      & + .content__item:not(.content__item--image) {
        order: 1;
        @include flexWidth(78.95%); //24.0rem

        // @include media($sm) {
        //   order: 0;
        //   @include flexWidth(78.95%);//33.4rem
        //   // margin: 0 auto;
        // }

        @include media($md) {
          order: 1;
          @include flexWidth(33.4rem);
          margin: 0 auto;
        }

        @include media($lg) {
          order: 0;
          margin: 7.1rem -5.5rem 0 auto;
          position: relative;
        }

        @include media($xl) {
          @include flexWidth(36.0rem);
        }

        h2 {
          @include media($md) {
            margin-bottom: 5.8rem;
          }
        }
      }

      &--image {
        @include flexWidth(11.2rem);
        margin-top: 0.8rem;
        margin-bottom: -12.9rem;
        margin-left: auto;

        @include media($sm) {
          @include flexWidth(36.845%); //38.698%
          // margin-top: 4.7rem;
          // margin-bottom: -11.3rem;
        }

        @include media($md) {
          @include flexWidth(29.1rem);
          margin-top: 4.7rem;
          margin-bottom: -11.3rem;
        }

        @include media($lg) {
          @include flexWidth(29.1rem);
          margin-left: 0;
          margin-bottom: 0;
        }

        @include media($lg2) {
          @include flexWidth(38.8rem);
        }

        @include media($xl) {
          @include flexWidth(41.9rem);
        }
      }
    }
  }

  // &--long-bg {
  //   @include media($md) {
  //     position: relative;
  //   }
  // }

  &--long-bg & {
    &__item {
      &--title {
        @include flexWidth(100%);

        @include media($md) {
          margin-bottom: 4.0rem;
        }
      }

      &--image {
        //margin-bottom: -1.0rem;
        margin-bottom: 1.6rem;
        position: relative;

        @include media($md) {
          @include flexWidth(33.6rem);
          margin-right: 4.8rem;
          margin-bottom: 0;
          //position: static;
        }

        @include media($lg) {
          margin-right: 12.0rem;
        }

        @include media($xl2) {
          @include flexWidth(41.3rem);
          margin-right: 20.0rem;
        }

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 1.3rem;
          background-color: #F3F4F6;
          transform: translateY(1.3rem);

          @include media($md) {
            top: 1.7rem;
            left: -0.8rem;
            height: auto;
            width: 100vw;
            z-index: -1;
            transform: none;
          }

          @include media($lg) {
            width: 96.4rem;
          }
        }
      }

      &--text {
        position: relative;

        @include media($md) {
          @include flexWidth(36.8rem);
          padding-bottom: 3.6rem;
          margin-top: -1.6rem;
        }

        @include media($lg) {
          @include flexWidth(48.0rem);
        }

        // h2 {
        //   @include media($md) {
        //     max-width: 30.0rem;
        //   }
        // }
      }

      .btn {
        margin-top: 3.2rem;

        @include media($md) {
          margin-top: 2.4rem;
        }
      }
    }
  }

  &--image-bg {
    @include media($md) {
      align-items: flex-start;
    }
  }

  &--image-bg & {
    &__item {
      @include media($md) {
        @include flexWidth(50%);
      }

      &--image {
        //margin-bottom: -1.0rem;
        margin-bottom: 1.6rem;
        position: relative;

        @include media($md) {
          order: 1;
          text-align: center;
          padding-left: 0.8rem;
        }

        @include media($lg) {
          padding-left: 3.3rem;
        }

        @include media($xl) {
          padding-left: 2.4rem;
        }

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 1.3rem;
          background-color: #F3F4F6;
          transform: translateY(1.3rem);
          z-index: -1;

          @include media($md) {
            left: 0.8rem;
            right: 0;
            height: 100%;
            width: auto;
            transform: none;
          }

          @include media($lg) {
            left: 3.3rem;
          }
        }
      }

      &--video {
        //margin-bottom: -1.0rem;
        margin-bottom: 1.6rem;
        position: relative;

        @include media($md) {
          order: 1;
          text-align: center;
          padding-left: 0.8rem;
        }

        @include media($lg) {
          padding-left: 3.3rem;
        }

        @include media($xl) {
          padding-left: 2.4rem;
        }

        h3 {
          margin-top: 30px;
        }
      }

      &--text {
        position: relative;

        @include media($md) {
          padding-right: 0.8rem;
        }

        @include media($lg) {
          padding-right: 3.3rem;
        }

        @include media($xl) {
          padding-right: 2.4rem;
        }
      }
    }
  }

  &--price + &--price {
    margin-top: 30px;
  }

  &--price & {
    &__item {
      &--title {
        @include flexWidth(100%);

        @include media($md) {
          @include flexWidth(calc(100% - 50.9rem));
        }

        @include media($lg) {
          @include flexWidth(40.8rem);
        }

        h2 {
          @include media($md) {
            margin-bottom: 0;
          }
        }
      }

      &--price {
        @include flexWidth(100%);

        @include media($md) {
          @include flexWidth(50.9rem);
        }
      }

      &--btn {
        @include flexWidth(100%);
        margin-top: 0;
        @include media($md) {
          margin-top: 3.2rem;
        }
      }
    }
  }

  &--image-no-mobile {
    @include media($md) {
      position: relative;
      padding-bottom: 4.8rem;
    }

    &:before {
      @include media($md) {
        content: "";
        position: absolute;
        top: 2.5rem;
        bottom: 0;
        left: 0;
        display: block;
        width: 70.4rem;
        background-color: #F3F4F6;
        z-index: -1;
      }
    }
  }

  &--image-no-mobile & {
    &__item {
      @include media($md) {
        @include flexWidth(50%);
      }

      &--title {
        @include flexWidth(100%);
      }

      &--image {
        display: none;

        @include media($md) {
          display: block;
          padding-right: 0.8rem;
        }
      }

      &--text {
        @include media($md) {
          padding-left: 0.8rem;
        }

        p + h3 {
          margin-top: 2.4rem;

          @include media($md) {
            margin-top: 3.2rem;
          }
        }
      }
    }
  }

  &--center {
    justify-content: center;
  }
}

.section {
  margin-bottom: 6.4rem;

  &--360 {
    display: none;

    @include media($md) {
      display: block;
    }

    // @include media($xl) {
    //   margin-bottom: -6.9rem;
    // }
  }

  &--long-bg {
    @include media($xl) {
      margin-bottom: 4.8rem;
    }
  }

  &--text {
    p {
      @include media($sm) {
        max-width: 56.0rem;
      }

      @include media($lg) {
        max-width: 60.0rem;
      }
    }
  }

  &--price {
    @include media($xl) {
      margin-bottom: 7.9rem;
    }

    @include media($xl2) {
      margin-bottom: 10.4rem;
    }
  }

  &--userExperience {
    .content__item {
      display: flex;
      flex-direction: column;
    }

    .h2--regular {
      margin: 1.6rem 0 0;
    }
  }
}

.price {
  &__item {
    display: flex;
    margin-bottom: 2.4rem;

    @include media($md) {
      margin-bottom: 3.2rem;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:after {
      content: "";
      display: block;
      border-bottom: 1px solid #E8EAEB;
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }
  }

  &__title {
    @include fontStyle($base, 1.5rem, 2.0rem, 400);
    color: #000000;
    padding-right: 0.8rem;

    @include media($md) {
      @include fontSize(1.8rem, 2.5rem);
    }
  }

  &__number {
    @include fontStyle($base, 1.5rem, 1.8rem, 400);
    color: #000000;
    order: 1;
    padding-left: 0.8rem;

    @include media($md) {
      @include fontSize(2.0rem, 2.4rem);
    }
  }
}

.consumables {
  flex-wrap: wrap;

  @include media($lg) {
    display: flex;
    margin-right: -1.2rem;
    margin-left: -1.2rem;
  }

  @include media($xl) {
    margin-right: -4.2rem;
    margin-left: -4.2rem;
  }

  &-section {
    margin-top: 6.4rem;

    h2 {
      @include media($lg) {
        max-width: 60.0rem;
        margin-bottom: 4.8rem;
      }
    }

    p {
      font-weight: 300;

      @include media($md) {
        margin-top: 4.8rem;
        @include fontSize(1.6rem, 2.2rem);
      }
    }
  }

  &__item {
    margin-bottom: 3.2rem;

    @include media($md) {
      @include flexWidth(46.5rem);
    }

    @include media($lg) {
      @include flexWidth(33.333%);
      padding: 0 1.2rem;
    }

    @include media($xl) {
      padding: 0 4.2rem;
    }
  }

  &__title {
    display: flex;
    align-items: center;

    span {
      margin-left: 0.8rem;

      @include media($md) {
        @include fontSize(2.1rem, 3rem);
      }
    }
  }

  &__price {
    margin-top: 3.2rem;

    .btn {
      margin-top: 20px;
    }
  }
}

.section-grey {
  margin-top: 6.4rem;
  margin-bottom: 0;
  padding: 2.4rem 0;
  background-color: #F3F6FA;
  display: none;

  @include media($md) {
    display: block;
    padding: 6.4rem 0;
  }

  .container {
    @include media($lg) {
      max-width: 94.9rem;
    }
  }

  p {
    text-align: center;
  }
}

.accordion {
  @include media($lg) {
    max-width: 75.2rem;
  }

  @include media($xl) {
    max-width: 91.2rem;
  }

  @include media($xl2) {
    max-width: 93.6rem;
  }

  &-section {
    margin-top: 4.8rem;

    @include media($lg) {
      margin-top: 6.4rem;
    }
  }

  &__title {
    position: relative;
    @include fontStyle($base, 1.5rem, 2.0rem, 400);
    color: #000000;
    padding: 1.6rem 2.8rem 1.6rem 0;
    border-top: 0.1rem solid #E8EAEB;
    cursor: pointer;

    @include media($md) {
      @include fontSize(1.8rem, 2.5rem);
      padding: 2.4rem 9.8rem 2.4rem 0;
    }

    &:before {
      content: "";
      position: absolute;
      top: 2.5rem;
      right: 0;
      width: 2.0rem;
      height: 0.2rem;
      background: #000000;
      transform: rotateZ(90deg);
      transition: all .3s ease-in;

      @include media($md) {
        top: 3.6rem;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 2.5rem;
      right: 0;
      width: 2.0rem;
      height: 0.2rem;
      background: #000000;

      @include media($md) {
        top: 3.5rem;
      }
    }
  }

  &__content {
    display: none;
    padding-top: 0.7rem;
    padding-bottom: 1.6rem;
    font-weight: 300;

    @include media($md) {
      padding-top: 0;
      padding-bottom: 2.4rem;
    }

    p {
      font-weight: 300;

      @include media($md) {
        @include fontSize(1.8rem, 2.8rem);
      }

      & + p {
        margin-top: 2.0rem;

        @include media($md) {
          margin-top: 2.8rem;
        }
      }
    }
  }

  &.active & {
    &__title {
      border-bottom: transparent;

      &:before {
        transform: rotateZ(0deg);
      }
    }

    &__content {
      display: block;
    }
  }

  & + .btn {
    margin-top: 1.6rem;
  }
}

.rating {
  display: flex;
  margin: 0 -0.3rem;

  &__item {
    margin: 0 0.3rem;

    &:before {
      content: "";
      display: block;
      width: 1.1rem;
      height: 1.1rem;
      background-image: url(../../assets/rating.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

.reviews {
  margin-bottom: 4.8rem;

  @include media($md) {
    margin-bottom: 7.3rem;
    display: flex;
    align-items: flex-start;
  }

  &-section {
    margin-top: 6.4rem;
    margin-bottom: 0;
  }

  &__number {
    @include fontStyle($base, 2.0rem, 2.7rem, 400);
    color: #000000;
    margin-bottom: 0.8rem;

    @include media($md) {
      @include fontSize(2.5rem, 3.4rem);
      margin-bottom: 1.6rem;
    }
  }

  &__info {
    padding: 2.4rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 3.0rem rgba(0, 0, 0, 0.1);

    @include media($md) {
      @include flexWidth(27.2rem);
      padding: 3.2rem 2.2rem;
      box-shadow: none;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    @include media($lg) {
      @include flexWidth(30.4rem);
    }

    @include media($xl2) {
      @include flexWidth(29.2rem);
    }

    &-rating {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 0.1rem solid #E8EAEB;
      margin-bottom: 1.6rem;
      padding-bottom: 1.6rem;
      text-align: center;

      @include media($md) {
        margin-bottom: 3.2rem;
        padding-bottom: 3.2rem;
      }
    }

    &-count {
      border-bottom: 0.1rem solid #E8EAEB;
      margin-bottom: 3.2rem;
      padding-bottom: 1.6rem;
      text-align: center;

      @include media($md) {
        padding-bottom: 3.2rem;
      }
    }

    p {
      @include fontStyle($base, 1.5rem, 2.0rem, 300);
      color: #000000;
    }

    .btn {
      @include media($md) {
        width: 100%;
      }
    }
  }

  &__text {
    @include fontStyle($base, 1.5rem, 2.5rem, 300);
    color: #000000;

    @include media($md) {
      @include fontSize(1.8rem, 2.5rem);
    }
  }

  &__imgvideo {
    display: flex;
    flex-wrap: wrap;

    .videoItem {
      padding: 0.8rem 0.8rem 0 0.8rem;
      overflow: hidden;
    }
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
    margin: 1.6rem -0.8rem 0;

    &-item {
      padding: 0.8rem 0.8rem 0 0.8rem;
      width: auto;
      height: 150px;
      display: flex;
      cursor: pointer;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include flexWidth(100%);

    @include media($md) {
      margin-left: 1.6rem;
      @include flexWidth(calc(100% - 30.4rem));
    }

    @include media($lg) {
      margin-left: 7.0rem;
      @include flexWidth(calc(100% - 37.4rem));
    }

    @include media($xl2) {
      margin-left: 6.8rem;
      @include flexWidth(calc(100% - 35.2rem));
    }

    .btn {
      margin-top: 3.2rem;
      order: 1;
    }
  }

  &__name {
    @include fontStyle($base, 1.5rem, 2.0rem, 400);
    color: #000000;

    @include media($md) {
      @include fontSize(2.0rem, 2.7rem);
    }
  }

  &__date {
    @include fontStyle($base, 1.5rem, 1.8rem, 300);
    color: #000000;

    @include media($md) {
      @include fontSize(1.6rem, 2.0rem);
    }
  }

  &__item {
    padding: 1.6rem 0;
    border-bottom: 0.1rem solid #E8EAEB;
    width: 100%;

    &:first-child {
      @include media($md) {
        padding-top: 0;
      }
    }

    &-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.8rem;
    }

    &-rating {
      margin-bottom: 1.6rem;
    }
  }
}

.form {

  &__wrap {
    display: none;
    width: 100%;
    max-width: 45.0rem
  }

  &--zakaz {
    max-width: 55.0rem
  }

  &__title {
    margin-top: 1.6rem;
    @include fontStyle($title, 1.6rem, 2.0rem, 400);

    @include media($md) {
      @include fontSize(1.8rem, 2.4rem);
      margin-bottom: 1.4rem;
    }
  }

  &-text {
    @include fontStyle($base, 1.5rem, 2.0rem, 400);
    width: 100%;
    //margin-top: 1.6rem;
    padding: 10px 29px;
    border: 1px solid #000000;
    border-radius: 4px;
    max-width: 100%;

    @include media($md) {
      @include fontSize(1.8rem, 2.8rem);
      //margin-top: 2.4rem;
    }
  }

  &-textarea {
    @include fontStyle($base, 1.5rem, 2.0rem, 400);
    margin-top: 1.6rem;
    width: 100%;
    padding: 10px 29px;
    border: 1px solid #000000;
    border-radius: 4px;
    max-width: 100%;

    @include media($md) {
      @include fontSize(1.8rem, 2.8rem);
      margin-top: 2.4rem;
    }
  }

  input[type="radio"] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
  }

  input[type="radio"] + label {
    position: relative;
    padding: 10px 20px 10px 28px;
    display: inline-block;
    cursor: pointer;

    span {
      position: absolute;
      left: 0;
      top: 12px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #eee;
      content: '';
      box-shadow: 0px 2px 2px -2px #444;
      display: block;
    }
  }

  input[type="radio"]:checked + label {
    span::before {
      position: absolute;
      left: 3px;
      top: 3px;
      width: 12px;
      height: 12px;
      background: #444;
      border-radius: 50%;
      content: '';
      display: block;
    }
  }

  .error {
    display: block;
  }

  &-submit {
    margin-top: 1.6rem;

    @include media($md) {
      margin-top: 2.4rem;
    }
  }
}

.fancybox__slide {
  padding: 0 !important;
}

.success {
  width: 100%;
  max-width: 450px;
  padding: 20px !important;

  &__icon {
    color: #15a55c;
    font-size: 4rem;
  }

  &__title {
    @include fontStyle($title, 2.0rem, 2.7rem, bold);
    color: #000000;
    margin-bottom: 1.6rem;

    @include media($md) {
      @include fontSize(3.6rem, 4.9rem);
      margin-bottom: 2.4rem;
    }
  }

  &__desc {
    @include fontStyle($base, 1.5rem, 2.0rem, 400);

    @include media($md) {
      @include fontSize(1.8rem, 2.8rem);
    }
  }
}


.blog {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  &-top {
    margin-top: 50px;
  }

  &--detail {
    margin: 3.2rem 0;
  }

  &-outer {
    & > span:hover {
      z-index: 3 !important;
    }
  }

  &__menu {
    @include flexWidth(100%);
    background: #FFFFFF;
    box-shadow: 0px 0px 3.0rem rgba(0, 0, 0, 0.1);
    padding: 3.2rem 2.2rem;

    @include media($md) {
      @include flexWidth(30.0rem);
    }

    ul {
      list-style: none;

      li {
        margin-bottom: 1.6rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__add-blog {
    margin-top: 3.2rem;
    padding-top: 3.2rem;
    border-top: .1rem solid #e8eaeb;
  }

  &__list {
    @include flexWidth(100%);

    @include media($md) {
      margin-left: 2.4rem;
      @include flexWidth(calc(100% - 30.0rem - 2.4rem));
    }

    &-pagination {
      margin-top: 3.2rem;
      margin-bottom: 3.2rem;
    }
  }

  &-body {
    margin-top: 30px;
  }

  &__detail {
    @include flexWidth(100%);
    margin-top: 3.2rem;

    @include media($md) {
      margin-top: 0;
      padding: 3.2rem 2.2rem;
      background-color: #fff;
      box-shadow: 0px 0px 3.0rem rgba(0, 0, 0, 0.1);
    }

    &-info {
      margin-bottom: 3.2rem;
    }

    &-footer {
      display: flex;
      flex-direction: row;
      align-content: center;
    }

    &_like {
      cursor: pointer;
      font-size: 18px;
      display: flex;

      div {
        padding-left: 8px;
        color: #342424;
        @include fontStyle($base, 1.2rem, 2.0rem, 400);
      }
    }

    &_share {
      margin-left: 14px;
      margin-top: -2px;
    }

    &-description {
      ul {
        list-style: none;
        margin-bottom: 3.2rem;

        li {
          position: relative;
          padding-left: 2.4rem;
          margin-top: 1.6rem;

          &:before {
            position: absolute;
            top: 1.2rem;
            left: 0;
            content: "";
            width: 2rem;
            height: 0.2rem;
            background-color: #000;
            display: block;
          }
        }
      }

      ol {
        list-style: none;
        counter-reset: myCounter;
        margin-bottom: 3.2rem;

        li {
          position: relative;
          padding-left: 2.4rem;
          margin-top: 1.6rem;

          &:before {
            counter-increment: myCounter;
            content: counter(myCounter) ")";
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  &-similar {
    margin-bottom: 3.2rem;
  }
}

.VideoModal {
  .ant-modal-body {
    padding: 0;
    margin: 0;
    font-size: 0;
  }
}
