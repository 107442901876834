@import '../../../../../styles/vars.scss';

.container {
    margin: -24px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    z-index: 9999;
    background: url('../../../../../assets/action/recipe-lam-back.jpg') center center no-repeat;
    background-size: cover;
    padding: 60px 24px;

    @media (min-width:600px) {
        flex-direction: row;
        padding: 45px 55px;
    }

}

.content {
    position: relative;
    border-radius: 14px;
    backdrop-filter: blur(16.156513214111328px);
    background: rgba(255, 255, 255, 0.6);
    padding: 20px;

    @media (min-width:600px) {
        padding: 24px 90px 24px 24px;
    }
}

.title {
    font-style: normal;
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 1;

    @media (min-width:480px) {
        font-size: 40px;
        line-height: 1.0771;
    }
}

.desc {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    margin-bottom: 20px;
    color: #000;
    font-size: 15px;

    @media (min-width:480px) {
        font-size: 14px;
    }
}

.heard {
    position: absolute;
    z-index: 1;
    background-image: url('../../../../../assets/action/recipe-lam.png');
    background-repeat: no-repeat;
    content: '';
    display: block;
    right: -40px;
    bottom: -87px;
    width: 202px;
    height: 178px;
    background-position: -165px 0;
    transform: scale(0.7);

    @media (min-width:480px) {
        right: -40px;
        bottom: -37px;
        width: 202px;
        height: 178px;
        background-position: -165px 0;
        transform: scale(1);
    }
}

.btn {
    border-radius: 10px;
    background: #2e2e2e;
    font-weight: 700;
    font-size: 12px;
    color: #fff;
    font-family: "Inter", sans-serif;
    display: inline-block;
    cursor: pointer;
    padding: 1.8rem 4rem;

    @media (min-width:480px) {
        padding: 1.8rem 6rem;
    }

    &:hover {
        background: lighten(#2e2e2e, 10%);
        color: #fff;
    }
}

.social {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 15px;

    &>a {
        position: relative;
        width: 50px;
        height: 50px;
        background-image: url('../../../../../assets/action/recipe-lam.png');
        background-repeat: no-repeat;
        content: '';
        display: block;

        &.tg {
            background-position: -63px 0px;
        }
    }
}