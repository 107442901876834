@import "../../styles/variables";
@import "../../styles/media";

.block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin: 0 0 42px;

    @include media($md) {
        flex-direction: row;
        margin: 50px 0;
    }
}

.blockText {
    width: 100%;

    @include media($lg) {
        width: 50%;
        padding-right: 40px;
    }
}

.blockVideo {
    width: 100%;

    @include media($lg) {
        width: 50%;
    }
}

.pharagrap {
    @include media($lg) {
        font-size: 20px;
        font-weight: 500;
        margin-top: 20px;
    }

    span {
        color: #8f8f8f;
        font-style: italic;
    }
}

.video {
    object-fit: cover;
    width: 100%;
    height: 100%;

    &_content {
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        width: 100%;
        max-width: 600px;
        height: 100%;
        min-height: 290px;
        max-height: 310px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgb(83, 83, 83);
        box-shadow: 0 21px 27px 0 rgba(0, 11, 40, 0.24);
    }

    &_content__hide &_icon {
        opacity: 0;
    }

    &_content__hide:hover &_icon {
        opacity: 0.8;
    }

    &_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 20px 40px 0 rgba(1, 16, 58, 0.14);
        text-align: center;
        line-height: 85px;
        font-size: 30px;
        position: absolute;
        transition: all 0.3s linear;
        cursor: pointer;
        z-index: 2;
        color: #17c7bd;

        &:after {
            transform: scale(3.8);
            content: "";
            width: 100%;
            height: 100%;
            border: 0.2px solid #fff;
            border-radius: 50%;
            opacity: 0.1;
            position: absolute;
            left: 0;
        }

        &::before {
            transform: scale(2.5);
            content: "";
            width: 100%;
            height: 100%;
            border: 0.2px solid #fff;
            border-radius: 50%;
            opacity: 0.1;
            position: absolute;
            left: 0;
        }
    }

    &_play {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #17c7bd;
        text-shadow: 0 6px 25px rgba(16, 147, 140, 0.9);
        position: relative;

        &:after {
            width: 80px;
            height: 80px;
            position: absolute;
            left: 50%;
            top: 50%;
            border-radius: 50%;
            box-shadow: 0 1px 15px 1px rgba(255, 255, 255, 0.5);
            content: "";
            animation: pulse 2s infinite;
        }
    }
}

@keyframes pulse {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }

    50% {
        transform: translate(-50%, -50%) scale(1.9);
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}