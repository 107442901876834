@import "../../../styles/variables";
@import "../../../styles/media";

.blogSlider {
    padding-bottom: 1.2rem;

    & > div[class=swiper-scrollbar] {
        bottom: 0;
    }
}

.image {
    height: 28rem;
    margin-bottom: 1.2rem;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
    }
}

.title {
    @include fontStyle($base, 1.8rem, 2.0rem, 600);
    color: #222;
    margin-bottom: 1.2rem;
    text-transform: none;
    display: inline-block;
}
