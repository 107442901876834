@import '../../../styles/vars.scss';

.infoItem {
    display: flex;
    justify-content: space-between;
}

.infoItemBig {
    flex-direction: column;
    justify-content: flex-start;

    .value {
        text-align: left;
        display: block;

        &:before {
            display: none;
        }
    }
}

.label {
    color: $gray2;
    white-space: nowrap;
}

.value {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: stretch;
    align-items: flex-start;

    &:before {
        content: '';
        border-bottom: 1px solid $gray1;
        flex: 1 1 auto;
        min-width: 24px;
        padding-top: 16px;
    }
}

.darken {
    color: rgb(209, 26, 26);
}