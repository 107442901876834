@import '../../../styles/vars.scss';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.form {
    padding: $gap-xl $gap-2xl;
    box-shadow: $light-shadow;
    border-radius: 5px;
    width: 460px;
}

.title {
    font-size: 26px;
}