@import '../../styles/vars.scss';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile {
    padding: 30px 25px;
    max-width: 100%;
    overflow-x: scroll;
}
