@import '../../styles/vars.scss';

.imagesList {
    display: flex;
    flex-wrap: wrap;
}

.wrapperImage {
    flex: 0 0 33.333%;
    border: 2px solid #fff;
}

.image {
    height: 150px!important;
    object-fit: cover;
}
