@import '../../styles/vars.scss';

.btn_center {
    display: table !important;
    margin-left: auto;
    margin-right: 0;
}

.info {
    margin-top: 24px;
    font-size: 11px;
}