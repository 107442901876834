@import '../../../styles/vars.scss';


.title {
    color: rgba(111, 111, 111, 0.79);
    font-weight: 600;
    font-size: 20px;
    line-height: 1.35;
}

.line {
    flex: 1 1;
}

.extra {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.cart {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
    border-radius: 6px;

    &_header {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
        padding: 24px;
    }

    &_pagination {
        padding: 24px;
    }
}