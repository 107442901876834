@import "../../styles/variables";
@import "../../styles/media";

.item {
    // width: 20%;
    padding: 0;
    margin: 0;
    // margin: 0 0 2rem;
    border-bottom: 1px solid #b7b7b7;
}

.title {
    @include fontStyle($base, 1.8rem, 2.0rem, 600);
    color: #222;
    margin-bottom: 1.2rem;
    text-transform: none;
    display: inline-block;
}

.header {
    padding: .2rem 0;
    color: #342424;
    @include fontStyle($base, 1.2rem, 2.0rem, 400);
}

.category{
    @include fontStyle($base, 1.4rem, 2.0rem, 400);
    color: #626264;
}

.content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 1.2rem 0;
}

.image {
    @include flexWidth(100%);
    margin-bottom: 1.2rem;

    img  {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: top center;
    }
}

.preview {
    @include fontStyle($base, 1.4rem, 2.0rem, 400);
    color: #000000;
}

.text {
    @include fontStyle($base, 1.4rem, 2rem, 300);
    color: #636363;

    @include media($md) {
        @include fontSize(1.6rem, 2.4rem);
    }
}

.nextpage {
    color: #342424;
    @include fontStyle($base, 1.2rem, 2.0rem, 400);
    text-transform: uppercase;
    margin-bottom: 2rem;
}

.footer {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: row;
    align-content: center;
}

.like{
    cursor: pointer;
    font-size: 18px;
    display: flex;
    div{
        padding-left: 8px;
        color: #342424;
        @include fontStyle($base, 1.2rem, 2.0rem, 400);
    }
}

.menu{
    cursor: pointer;
    font-size: 18px;
    margin-left: 14px;
    display: flex;
    div{
        padding-left: 8px;
        color: #342424;
        @include fontStyle($base, 1.2rem, 2.0rem, 400);
    }
}

.share{
    margin-left: 14px;
    margin-top: -2px;
}


.MenuCategories{
    width: 100%;
    display: block;
    border-bottom: 1px solid #b7b7b7;
    padding: 1rem;
    ul{
        list-style: none;
        display: flex;
        align-items: center;
        margin-left: -1rem;
        margin-right: -1rem;
        flex-wrap: wrap;
        li{
            margin-right: 15px;
        }
    }
}
.tagsList{
    width: 100%;
    display: block;
    border-bottom: 1px solid #b7b7b7;
    padding: 1rem 0;
    background: #fff;
    
}

.noborder{
    border: 0 !important;
    background-color: transparent !important;
}

.deleteTags{
    position: absolute;
    margin-top: 15px;
    max-width: 400px;
    width: 100%;
    padding: 20px;
    background: #fff;
    z-index: 999;
    box-shadow: 0px 4px 10px #d7d7d7;
    &_text{
        color:red;
        font-size: 13px;
    }
    &_close{
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        &:hover{
            color: #bbb;
        }
    }
    &_desc{
        margin-top: 20px;
        font-style: italic;
        font-size: 12px;
    }
}

.btnDelete{
    margin: 20px auto 0 auto;
    display: table;
    background: #eee;
    padding: 3px 6px;
    font-size: 12px;
    cursor: pointer;
    &:hover{
        background: darken(#eee, 10%);
    }
}