@import '../../styles/vars.scss';

.container {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
    border-radius: 6px;
    padding: 24px;
    margin-top: $gap-xl;
    margin-bottom: $gap-xl;
}

.formItem {
    margin-bottom: $gap-s!important;
}

.itemGroup {
    margin-bottom: $gap-xl;
}

.form {
    color: red;
}

.formLoading {
    opacity: 0.4;
    pointer-events: none;
}
