.itemVideo {
    padding: 0.8rem 0.8rem 0 0.8rem;
    height: 150px;
    display: flex;
    cursor: pointer;
}

.answer {
    margin: 15px 0;
    padding: 10px 0 10px 20px;
    border-left: 4px solid #eee;
    &__author {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 0.95em;
        font-style: italic;
    }
    p {
        margin: 0;
        padding: 0;
        font-size: 0.95em;
        font-style: italic;
    }
}
