@import "../../styles/vars.scss";

.header {
    background-color: $header-bg;
    border-bottom: 0.0625rem solid #e9ecef;
    box-shadow: 0px 0px 3rem rgba(0, 0, 0, 0.1);
}

.logo {
    max-width: 102px;
    flex: 0 0 auto;
}

.content {
    width: 100%;
    max-width: $container-w;
    margin-left: auto;
    margin-right: auto;
    padding: $gap-m;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.fline {
    flex: 1 1;
}

.menu {
    position: relative;
    margin: 0 20px 0 0;

    &__btn {
        position: relative;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 780px) {
            display: none;
        }

        span {
            position: relative;
            width: 100%;
            height: 1.5px;
            background: #323232;
            content: '';
            display: block;
            transition: all 0.7s;

            &::after,
            &::before {
                position: absolute;
                top: -10px;
                left: 0;
                width: 100%;
                height: 1.5px;
                background: #323232;
                content: '';
                display: block;
                transition: all 0.7s;
            }

            &::after {
                top: auto;
                bottom: -10px;
            }
        }

        &_active {
            span {
                background: transparent;

                &::before {
                    transition: all 0.7s;
                    transform: rotate(-45deg) translate(-7px, 7px);
                }

                &::after {
                    transition: all 0.7s;
                    transform: rotate(45deg) translate(-7px, -7px);
                }
            }
        }
    }

    &>ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        gap: 10px;

        @media (max-width: 779px) {
            display: none;
            flex-direction: column;
            position: absolute;
            right: 0;
            margin-top: 10px;
            min-width: max-content;
            z-index: 99;
            padding: 10px;
            background: #fff;
            box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
            border-radius: 6px;
        }

        @media (min-width: 780px) {
            flex-direction: row;
        }

        &.menu__open {
            @media (max-width: 779px) {
                display: flex;
            }
        }

        &>li:hover>.menu__link:not(.menu__active) {
            background-color: rgb(226 226 226);
        }

        .menu__active,
        li.active .menu__link {
            color: #fefefe !important;
            background-color: #161717;

            span {
                color: #fefefe !important;
            }

            sup {
                top: -10px;
            }
        }

        li:hover .menu__sub {
            display: flex !important;
        }
    }

    &__sub {
        display: none;
        list-style: none;
        margin: 0;
        position: absolute;
        transform: translateX(-35px);
        padding: 10px;
        margin-top: 10px;
        background: #fff;
        border: 1px solid #f8f9fa;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        z-index: 3;
        flex-direction: column;
        gap: 7px;

        &::before {
            position: absolute;
            top: -13px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 14px solid #ffffff;
            content: '';
        }

        &::after {
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 20px;
            content: "";
        }

        a {
            display: block;
        }
    }

    &__header,
    &__link {
        cursor: pointer;
        transition: all 0.4s;
        padding: 0.5rem 0.73rem;
        color: #171717;
        border-radius: 5px;
        font-size: 15px;
        line-height: 1.2;
        margin: 0;
        white-space: nowrap;
        display: block;

        @media (max-width: 779px) {
            display: block;
        }

        sup {
            top: -5px;
        }
    }
}

.profile {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profileName {
    margin-left: $gap-m;
    cursor: pointer;
    font-size: 13px;
    line-height: 1;

    @media (max-width: 991px) {
        display: none;
    }

}

.profileAngle {
    cursor: pointer;
    margin-left: 10px;
}

.popap {
    position: relative;

    &_menu {
        position: absolute;
        right: 0;
        margin-top: 10px;
        z-index: 99;
        min-width: 230px;
        display: none;
        flex-direction: column;
        border-radius: 5px;
        background: #fff;
        gap: 4px;
        box-shadow: 0 4px 11px rgba(0, 0, 0, 0.17), 0 0 transparent, 0 0 transparent;
        padding: 20px 10px;

    }

    &_open {
        display: flex;
    }

    &__link {
        cursor: pointer;
        padding: 10px 16px;
        border-radius: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: normal;
        word-wrap: break-word;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        text-transform: none;
        color: rgb(22 23 23);
        display: block;
        transition: all 0.5s;

        &:hover {
            color: #fff;
            background: rgb(22 23 23);
        }
    }
}

.popap__link_active {
    color: #fff;
    background: rgb(22 23 23);
}