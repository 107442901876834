@use "sass:math";
// @media (min-width: 40em) // 640px
// @media (min-width: 60em) // 960px
// @media (min-width: 80em) // 1280px

@mixin media($width) {
  @media (min-width: $width) {
    @content;
  }
}

@mixin marginAuto {
  margin-left: auto;
  margin-right: auto;
}

@mixin cube($w, $h: $w) {
  width: $w;
  height: $h;
}

@mixin backgroundImage($url,
  $backgroundPos: 0% 0%,
  $backgroundRepeat: no-repeat,
  $backgroundSize: auto) {
  background-image: url($url);
  background-position: $backgroundPos;
  background-repeat: $backgroundRepeat;
  background-size: $backgroundSize;
}

@mixin before($top, $left, $right: auto, $bottom: auto) {
  content: "";
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}

@mixin textPreview($max-height: 132px, $lines: 6) {
  display: block;
  display: -webkit-box;
  height: $max-height;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin fontStyle($font, $fontSize: 12px, $lineHeight: 1, $fontWeight: normal) {
  font-family: $font;
  font-size: $fontSize;
  line-height: math.div($lineHeight, $fontSize);
  font-weight: $fontWeight;
}

@mixin fontSize($fontSize: 12px, $lineHeight: 1) {
  font-size: $fontSize;
  line-height: math.div($lineHeight, $fontSize);
}

@mixin flex($horizontal: flex-start, $vertical: flex-start, $wrap: wrap) {
  display: flex;
  justify-content: $horizontal;
  align-items: $vertical;
  flex-wrap: $wrap;
}

@mixin flexWidth($procent) {
  flex: 0 0 $procent;
  width: $procent;
  max-width: $procent;
}

// трансформация, плавный переход
// пример: @include transit(color,.2s,ease-in-out);
@mixin transit($what: all, $when: 0.25s, $how: ease) {
  -webkit-transition: $what $when $how;
  -moz-transition: $what $when $how;
  -ms-transition: $what $when $how;
  -o-transition: $what $when $how;
  transition: $what $when $how;
}