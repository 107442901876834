@import "../../styles/vars.scss";

.filter {
  box-shadow: $light-shadow;
  margin: $gap-xl 0;
  display: grid;
  gap: $gap-xl;
  background: #fff;
  padding: 24px;
  border-radius: 6px;

  grid-template-areas: "type brand status tone shade all result";
  @media (max-width: 1040px) {
    grid-template-areas:
      "type brand"
      "status tone"
      "shade all"
      ".   result";
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 1040px) {
    grid-template-areas:
      "type"
      "brand"
      "status"
      "tone"
      "shade"
      "all"
      "result";
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }
}

.filterItemFormType {
  width: 100%;
  max-width: 200px;
  grid-area: type;
  @media (max-width: 1040px) {
    max-width: 100%;
  }
}
.filterItemBrand {
  width: 100%;
  grid-area: brand;
}
.filterItemStatus {
  width: 100%;
  grid-area: status;
}
.filterItemTone {
  width: 100%;
  grid-area: tone;
}
.filterItemResult {
  width: 100%;
  grid-area: result;
}
.filterItemShade {
  width: 100%;
  grid-area: shade;
}
.filterItemAll {
  display: flex;
  align-items: center;
  width: 100%;
  grid-area: all;
}
.Select {
  width: 100%;
}
