@import '../../styles/vars.scss';

.filter {
    box-shadow: $light-shadow;
    padding: $gap-l $gap-m $gap-xl;
    margin: $gap-xl 0;
    display: grid;
    gap: $gap-xl;
    grid-template-areas:"type discount time status result";
    @media (max-width: 1040px){
        grid-template-areas:
        "type discount"
        "time status"
        ".   result";
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 1040px){
        grid-template-areas:
        "type"
        "discount"
        "time"
        "status"
        "result";
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
    }
}

.filterItemFormType {
    width: 100%;
    max-width: 200px;
    grid-area:type;
    @media (max-width: 1040px){
        max-width: 100%;
    }
}
.filterItemDiscount {
    width: 100%;
    grid-area:discount;
}
.filterItemTime {
    width: 100%;
    grid-area:time;
}
.filterItemStatus {
    width: 100%;
    grid-area:status;
}
.filterItemDatePicker {
    width: 100%;
    grid-area:date;
}
.filterItemResult {
    width: 100%;
    grid-area:result;
}
.Select{
    width: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination {
    margin-top: $gap-l;
}

.spin{
    margin: 0 auto;
    display: block;
}