@import '../../styles/vars.scss';

.group {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    background: #fff;
    box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
    border-radius: 6px;
    padding: 24px;
    margin-bottom: $gap-xl;
}

.status {
    font-weight: 700;
}

.table {
    max-width: 100%;
    overflow-x: scroll;
}

.cart {
    display: flex;
    flex-direction: column;
}