@import '../../styles/vars.scss';

.filter {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: -webkit-fill-available;

    @media (min-width: 768px) {
        flex-direction: column;
    }
}

.filterBase {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: -webkit-fill-available;

    @media (min-width: 768px) {
        flex-direction: row;
    }
}



.filterItemFormType {
    width: 100%;
    max-width: 200px;
    grid-area: type;

    @media (max-width: 1040px) {
        max-width: 100%;
    }
}

.filterItemBrand {
    width: 100%;
    grid-area: brand;
}

.filterItemStatus {
    width: 100%;
    grid-area: status;
}

.filterItemDatePicker {
    width: 100%;
    grid-area: date;
}

.filterItemResult {
    width: 100%;
    grid-area: result;
}

.Select {
    width: 100%;
}