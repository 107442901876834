@import '../../styles/vars.scss';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table {
    max-width: 100%;
    overflow-x: scroll;
}

.actions {
    display: flex;
    justify-content: flex-end;
}

.status {
    font-weight: 700;
    margin-right: $gap-m;
}

.pagination {
    margin-top: $gap-l;
}