.uploadImage {
    display: flex;
    flex-direction: column;
    gap: 8xp;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: border-color 0.3s;
}