@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon.ttf?cyd0pv") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="_icon-"],
[class*=" _icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._icon-success1:before {
  content: "\e901";
}
._icon-menu:before {
  content: "\e917";
}
._icon-vk:before {
  content: "\e900";
}
._icon-whatsap:before {
  content: "\e902";
}
._icon-telegram:before {
  content: "\e904";
}
._icon-instagram:before {
  content: "\e906";
}
._icon-clock:before {
  content: "\e90a";
}
._icon-car-engine:before {
  content: "\e90b";
}
._icon-car-parts:before {
  content: "\e90c";
}
._icon-close:before {
  content: "\e90d";
}
._icon-message:before {
  content: "\e90e";
}
._icon-exhaust-pipe:before {
  content: "\e90f";
}
._icon-location:before {
  content: "\e910";
}
._icon-steering-wheel:before {
  content: "\e911";
}
._icon-phone:before {
  content: "\e912";
}
._icon-wheels:before {
  content: "\e913";
}
._icon-windscreen:before {
  content: "\e914";
}
._icon-wrench:before {
  content: "\e915";
}
._icon-group:before {
  content: "\e916";
}
._icon-plus:before {
  content: "\e918";
}

@font-face {
  font-family: 'Constantia';
  src: url('../assets/fonts/Constantia/Constantia.eot');
  src: url('../assets/fonts/Constantia/Constantia.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Constantia/Constantia.woff2') format('woff2'),
      url('../assets/fonts/Constantia/Constantia.woff') format('woff'),
      url('../assets/fonts/Constantia/Constantia.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

