@import '../../styles/vars.scss';

.imagesBlock {
    padding-right: $gap-xl;
    margin-bottom: $gap-xl;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (min-width: 768px) {
        flex-direction: row;
    }

    &_content {
        flex: 1 0;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    &__title {
        margin-bottom: 0 !important;
    }

    &_status {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
            padding: 4px 8px;
            border-radius: 4px;
            color: #fff;
            font-size: 13px;
            font-weight: bold;
        }
    }
}

.imageErrors {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    svg {
        margin-top: -10px;
        color: red;
    }
}

.btnIcon {
    display: flex !important;
    align-items: center;
    gap: 6px;

    svg {
        width: 16px;
    }
}

.imagesList {
    display: flex;
}

.wrapperImage {
    flex: 0 0 33.333%;
    border: 2px solid #fff;
}

.image {
    height: 150px !important;
    object-fit: cover;
}

.statuses {
    margin-bottom: $gap-xl;
}

.updateStatusLoading {
    font-size: 16px;
    margin-left: $gap-m;
}

.imagesTitle {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
}

.imageError {
    font-size: 12px;
    padding-bottom: $gap-2xs;
}

.statusBtn {
    margin-right: $gap-s;
}

.supportBtn {
    margin-bottom: $gap-m;
}

.rejectionReason {
    color: $error-color;
}

.darken {
    color: rgb(209, 26, 26) !important;
}

.cartForm {
    display: block;
}

.cart {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
    border-radius: 6px;
    padding: 24px;

    &_line {
        width: 100%;
        height: 1px;
        background: #f0f0f0;
        content: '';
        display: block;
        margin: 10px 0;
    }

    &_blank {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &_two {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media (min-width: 768px) {
                flex-direction: row;
            }

        }
    }
}

.return {
    margin-top: 20px;
}

.mb20 {
    margin-bottom: 20px;
}

.flex\:1 {
    flex: 1;
}

.flex_1\:7 {
    flex: 1.4 0;
}

.aboutChooseColor {
    font-size: 14px;
    color: #222;
}

.statusTest {
    background: #aec122;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: bold;
}