@import '../../styles/vars.scss';

.infoHeading {
    font-size: 16px;
}

.buttons {
    display: flex;
    margin-bottom: $gap-m;

    &>* {
        margin-right: $gap-s;
    }
}

.messageBlock {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.messageInput {
    width: 100%;
    margin-bottom: $gap-m;
}

.link {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: $primary;
}

.date {
    padding-left: 10px;
    color: #bbb;
    font-weight: 400;
}

.message {
    padding: 20px;
}

.comment {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 6px;

    &__ava {
        display: flex;
        flex: 0 1;
    }

    &_content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
    }
}

.cart {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    background: #fff;
    box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
    border-radius: 6px;
    padding: 24px;
}