@import '../../../styles/vars.scss';


.filter {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 24px 0;
    gap: 24px;
    background: #fff;
    padding: 24px;
    border-radius: 6px;

    &_title {
        color: rgb(23 23 24);
        font-weight: 600;
        font-size: 16px;
        align-items: center;
        gap: 20px;
        display: flex;

        @media (min-width: 992px) {
            display: none;
        }
    }

    &_content {
        display: none;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;

        @media (min-width: 992px) {
            display: flex;
            flex-direction: row;
        }

    }

    &_fields {
        flex: 0 1 100%;
        display: flex;
        flex-direction: column;
        gap: 14px;
        width: -webkit-fill-available;

        @media (min-width: 768px) {
            flex-direction: row;
        }
    }
}

.open {
    display: flex !important;
}

.hidden {
    display: none !important;
}