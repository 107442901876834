.header {
  position: sticky;
  top: 0;
  background: $white;
  padding: 0.8rem 0;
  z-index: 5;

  @include media($sm) {
    padding: 1.4rem 0 1.5rem;
  }
  
  &__container {
    display: flex;
    align-items: center;

    @include media($xl2) {
      max-width: 144.0rem;
    }
  }

  // .header__menu
  &__menu {
    position: fixed;
    top: 5.4rem;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: translateX(100%);
    transition: all .5s ease;
    padding: 0 0.8rem 1.6rem;

    @include media($lg) {
      position: relative;
      padding: 0;
      display: flex;
      top: auto;
      right: auto;
      transform: none;
      margin-left: auto;
      width: auto;
      // @include flexWidth(72.7rem)
    }
    // @include media($lg) {
    //   margin-left: 8rem;
    // }

    .ant-anchor-ink {
      display: none;
    }

    &.open {
      transform: translateX(0);
    }
  }
  // &__social {
  //   display: none;
  //   @include media($lg) {
  //     display: block;
  //   }
  // }

  &__btns {
    display: none;

    @include media($sm) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
    }

    @include media($lg) {
      margin-left: 2.4rem;
    }
    
    @include media($xl) {
      margin-left: 6.0rem;
    }

    .btn {
      margin-right: 2.4rem;

      &:last-child {
        @include media($lg) {
          margin-right: 0;
          padding: 0.8rem 1.2rem;
        }
      }
    }

    // @include media($lg) {
    //   margin-right: 2.4rem;
    // }
  }
}


.menu {
  list-style: none;
  padding: 0;
  margin: 0;

  @include media($lg) {
    display: flex;
  }

  &__item {
    display: block;
    
    @include media($lg) {
      margin-right: 1.6rem;
    }

    @include media($xl) {
      margin-right: 2.4rem;
    }

    &:last-child {
      @include media($lg) {
        margin-right: 0;
      }
    }

    &--hidden-mobile {
      display: none;

      @include media($lg) {
        display: block;
      }
    }
  }
  &__link {
    @include fontStyle($title, 1.4rem, 2.3rem, bold);
    text-transform: uppercase;
    color: #000000;

    @include media($xl) {
      @include fontSize(1.8rem, 2.5rem);
    }
  }
}

.burger {
  position: relative;
  width: 18px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;

  @include media($sm) {
    margin-left: 0;
  }

  @include media($lg) {
    display: none;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000000;
  }

  span {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000000;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000000;
  }
}

.mobile-btn-auth{
  @media (min-width: 480px){
    display: none !important;
  }
  
  margin-top: 40px;
  a + button,
  button + button{
    margin-top: 20px;
  }
}

.promocode {
  position: relative;
  z-index: 3;
  padding: 20px 0;
  background-color: #f2deea;
  color: #000;
  text-shadow: 0px 0px #000;
  font-size: 18px;
  text-align: center;
  box-shadow: 0px 0px 3.0rem rgba(0, 0, 0, 0.1);

  &:hover &__btn {
    display: block;
  }

  &__content {
    position: relative;
  }

  &__btn {
    position: absolute;
    top: 50%;
    right: 0;
    display: none;
    transform: translateY(-50%);
  }
}