@import '../../styles/vars.scss';

.container {
    margin-top: $gap-xl;
    margin-bottom: $gap-xl;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
    border-radius: 6px;
    padding: 24px;
}

.formItem {
    margin-bottom: $gap-s !important;
}

.itemGroup {
    margin-bottom: $gap-xl;
}

.form {
    overflow-y: auto;
}

.formLoading {
    opacity: 0.4;
    pointer-events: none;
}

.cell {
    padding: $gap-s;
    border: 1px solid black;
}

.table {
    min-width: 1024px;
}

.flx {
    display: flex;
    align-items: center;
}

.checkbox {
    margin-left: $gap-s !important;
    margin-bottom: $gap-2xs !important;
}

.heading {
    margin-bottom: 0 !important;
}